import { graphql } from 'gatsby'
import Collection from '../containers/Collection'

export default Collection

export const pageQuery = graphql`
  query allArticleByCategoryQuery($slug: String!) {
    collectionInfo: strapiCategory(slug: { eq: $slug }) {
      id
      name
      slug
      description
    }
    seo: strapiCategory(slug: { eq: $slug }) {
      id
      seo {
        metaDescription
        metaTitle
        keywords
      }
    }
    videos: allStrapiVideo(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        authors {
          id
          name
          title
          slug
          updatedAt
          social {
            name
            url
          }
        }
      }
    }
    blogs: allStrapiBlog(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        categories {
          name
          slug
        }
        excerpt {
          data {
            excerpt
          }
        }
        body {
          data {
            body
          }
        }
      }
    }
    events: allStrapiEvent(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [eventdate], order: DESC }
    ) {
      nodes {
        id
        title
        slug
        categories {
          name
          slug
        }
        featuredImage {
          ImageSharp_vertical: localFileImageSharp {
            gatsbyImageData(
              width: 360
              height: 470
              transformOptions: { cropFocus: CENTER }
              outputPixelDensities: [0.5, 1]
            )
          }
        }
        excerpt {
          data {
            excerpt
          }
        }
        body {
          data {
            body
          }
        }
      }
    }
  }
`
