import React, { useEffect } from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { attachPostDetails, attachAuthorDetails } from '@utils-blog'
import { useEventSlug, usePostExcerpt,getReadTimeFromBlog } from '@helpers-blog'
import { readingTime } from 'reading-time-estimator'


const PageCollection = ({
  data: { videos, blogs, events, collectionInfo, seo },
  ...props
}) => {
  if (events.nodes && events.nodes.length > 0) {
    events.nodes.forEach(item => {
      item.slug = useEventSlug(item.title)
      item.excerpt = usePostExcerpt(item.title)
      item.bodyContent = usePostExcerpt(item.title)
    })
  }
  if (blogs.nodes && blogs.nodes.length > 0) {
    blogs.nodes.forEach(item => {
      item.bodyContent = usePostExcerpt(item.title)
      item.excerpt = item.bodyContent
    })
  }
  if (videos.nodes && videos.nodes.length > 0) {
    videos.nodes.forEach(item => {
      item.excerpt = usePostExcerpt(item.title)
      item.bodyContent = usePostExcerpt(item.title)
    })
  }
  attachAuthorDetails(collectionInfo)

  blogs.nodes = addKey(blogs.nodes, 'Blog')
  // posts.nodes = addKey(posts.nodes, 'Post')
  videos.nodes = addKey(videos.nodes, 'Video')
  events.nodes = addKey(events.nodes, 'Event')

   
  blogs.nodes.forEach(ele => {
    ele.bodyContent = getReadTimeFromBlog(ele.title)
    if (ele.bodyContent && ele.bodyContent.body) {
      const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
      ele.callFrom='Blog'
    }
     })
  const data = [...videos.nodes, ...blogs.nodes, ...events.nodes]
  const item = groupBy([...data], c => c.belongTo)

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    )
  }
  function addKey(list, key) {
    list.forEach(item => {
      item.belongTo = key
    })
    return list
  }
  const object = { nodes: data }
  attachPostDetails(object) // Attach missing post details

  const categoriesSeoData = {
    ...seo,
    seo: { ...seo?.seo, keywords: seo?.seo?.keywords?.split('\n') }
  }

  return (
    <Layout {...props}>
      <Seo
        title={categoriesSeoData?.seo?.metaTitle}
        description={categoriesSeoData?.seo?.metaDescription}
        keywords={categoriesSeoData?.seo?.keywords}
        thumbnail={{
          url: 'https://newmanreview-assets.s3.ap-south-1.amazonaws.com/syposium_4bf3fb68e1.jpg'
        }}
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={collectionInfo.name}
          subheader={collectionInfo.title}
          totalCount={data.length}
        />
      </Stack>
      <Divider />
      {Object.keys(item).map(key => {
        return (
          <Stack title={key}>
            <Main>
              {item[key] && (
                <>
                  <CardList
                    nodes={item[key]}
                    variant={['horizontal-md', 'vertical']}
                    columns={[1, 2, 3, 3]}
                    omitCategory={
                      props.pageContext &&
                      props.pageContext.collectionType === 'category'
                    }
                  />
                  <Divider />
                </>
              )}
            </Main>
            <Divider />
          </Stack>
        )
      })}
      <Divider />
      {/* <PreFooter>
        <Pagination
          {...blogs.pageInfo}
          {...collectionInfo}
          {...{
            slug: `/category/${collectionInfo.slug}/`
          }}
        />
      </PreFooter> */}
    </Layout>
  )
}

export default PageCollection
